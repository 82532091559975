<template>
	<div class="emb-gadget-wrap mt-14">
		
			<v-toolbar color="green" dark extended flat> </v-toolbar>

			<v-card color="primary" class="mx-auto mb-10 mt-5" max-width="1420">
				<v-toolbar flat>
					<v-toolbar-title>Consultar comprobante</v-toolbar-title>

					<v-spacer></v-spacer>
				</v-toolbar>

				<v-divider></v-divider>

				<v-card-text>
					<v-form ref="form" @submit.prevent="submit">

						<v-row>
							<v-col cols="12" xl="4" lg="4" md="12" sm="12">
								<label class="control-label mt-2">
                                    Tipo Documento
                                </label>
                                <el-select
                                    v-model="form.codigo_tipo_documento"
                                    @change="filterSeries"
                                >
                                    <el-option
                                        v-for="option in document_types"
                                        :key="option.id"
                                        :value="option.id"
                                        :label="option.description"
                                    ></el-option>
                                </el-select>
                                <small
                                    class="error--text"
                                    v-if="errors.codigo_tipo_documento"
                                    v-text="errors.codigo_tipo_documento[0]"
                                ></small>
							</v-col>
							<v-col cols="12" xl="4" lg="4" md="12" sm="12">
								<label class="control-label mt-2">Serie</label>
                                <el-select v-model="form.serie_documento">
                                    <el-option
                                        v-for="option in series"
                                        :key="option.number"
                                        :value="option.number"
                                        :label="option.number"
                                    ></el-option>
                                </el-select>
                                <small
                                    class="error--text"
                                    v-if="errors.serie_documento"
                                    v-text="errors.serie_documento[0]"
                                ></small>
							</v-col>

							<v-col cols="12" xl="4" lg="4" md="12" sm="12">
								<label class="control-label mt-2">Número</label>
                                <el-input
                                    v-model="form.numero_documento"
                                ></el-input>
                                <small
                                    class="error--text"
                                    v-if="errors.numero_documento"
                                    v-text="errors.numero_documento[0]"
                                ></small>
							</v-col>
						</v-row>
						<v-row>
							<v-col cols="12" xl="12" lg="12" md="12" sm="12">
								<label class="control-label mt-2"
                                    >Fecha de emisión</label
                                >
                                <el-date-picker
                                    v-model="form.fecha_de_emision"
                                    type="date"
                                    value-format="yyyy-MM-dd"
                                    format="dd/MM/yyyy"
                                    :picker-options="pickerOptions"
                                    :clearable="false"
                                ></el-date-picker>
                                <small
                                    class="error--text"
                                    v-if="errors.fecha_de_emision"
                                    v-text="errors.fecha_de_emision[0]"
                                ></small>
							</v-col>
						</v-row>
						<v-row>
							<v-col cols="12" xl="12" lg="12" md="12" sm="12">
								<label class="control-label mt-2">
                                    Monto total
                                </label>
                                <el-input-number 
                                    v-model="form.total"
                                    :controls="false"
                                    :precision="2"
                                    :min="0.1"
                                    :step="1"
                                    class="input-text-right"
                                >
                                </el-input-number>
                                <small
                                    class="error--text"
                                    v-if="errors.total"
                                    v-text="errors.total[0]"
                                ></small>
							</v-col>
						</v-row>
                        <v-row>
                            <v-col cols="12" xl="12">
                                <div class="table-responsive" v-if="record">
                                    <v-simple-table dark>
                                        <template v-slot:default>
                                            <thead>
                                                <tr>
                                                    <th>Cliente</th>
                                                    <th>Número</th>
                                                    <th class="text-right">Total</th>
                                                    <th class="text-right">Descargas</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr>
                                                    <td>{{ record.customer }}</td>
                                                    <td>{{ record.number }}</td>
                                                    <td class="text-right">
                                                        {{ record.total }}
                                                    </td>
                                                    <td class="text-right">
                                                        <v-btn
                                                            color="error"
                                                            class="mr-1"
                                                            x-small
                                                            dark
                                                            @click.prevent="clickDownload(record.download_xml)"
                                                        >
                                                            XML
                                                        </v-btn>
                                                        <v-btn
                                                            color="success"
                                                            x-small
                                                            dark
                                                            @click.prevent="clickDownload(record.download_pdf)"
                                                        >
                                                            PDF
                                                        </v-btn>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </template>
                                    </v-simple-table>
                                </div>
                            </v-col>
                        </v-row>
                        
                        <v-btn
                            class="mb-3 mt-5"
                            type="submit"
                            color="accent"
                            :loading="loading"
                            block
                            :disabled="loading"
                        >
                            Buscar
                        </v-btn>
					</v-form>
				</v-card-text>
			</v-card>
	</div>
</template>

<script>
import { confirm } from 'Mixins/confirm';
import dayjs from 'dayjs';

export default {
	mixins: [confirm],
	data() {
		return {
			loaded: false,
			loading: false,
			resource: 'search',
            record: null,
            all_series: [],
            series: [],
            document_types: [],
            company: {},
            result: {},
			form: {},
			errors: {},
			pickerOptions: {
				disabledDate: time => {
                    time = dayjs(time).format("YYYY-MM-DD");
                    return dayjs().format("YYYY-MM-DD") < time;
                }
			},
		};
	},
	async created() {
        this.initForm();
        await this.getTables();
    },
	methods: {
		initForm() {
			this.form = {
				ruc_emisor: null,
                codigo_tipo_documento: "01",
                serie_documento: null,
                numero_documento: null,
                fecha_de_emision: dayjs().format("YYYY-MM-DD"),
                total: null
			};
		},
		async getTables() {
            await this.$http.get(`/${this.resource}/tables`).then(resp => {
                this.company = resp.data.company;
                this.document_types = resp.data.document_types;
                this.all_series = resp.data.series;

                this.form.ruc_emisor = this.company
                    ? this.company.number
                    : null;
                this.filterSeries();
            });
        },
        filterSeries() {
            this.form.serie_documento = null;
            this.series = this.all_series.filter((s) => s.document_type_id == this.form.codigo_tipo_documento)
            
            this.form.serie_documento = this.series.length > 0 ? this.series[0].number : null;
        },
        clickDownload(download) {
			window.open(download, '_blank');
		},
		submit() {
            this.loading = true;
            this.record = null;
            this.$http
                .post(`/${this.resource}`, this.form)
                .then(resp => {
                    if (resp.data.success) {
                        
                        this.$message.success(resp.data.message);

                        if (resp.data.data.resource) {
                            console.log("Si exsito")
                            this.record = resp.data.data.resource;
                        }

                    } else {
                        this.$message.error(resp.data.message);
                    }

                    this.result = resp.data.data ? resp.data.data : {};
                })
                .catch(error => {
                    if (error.response.status === 422) {
                        this.errors = error.response.data.errors;
                    } else {
                        this.$message.error(error.response.data.message);
                    }
                })
                .then(() => {
                    this.loading = false;
                });
        }
	},
};
</script>
